import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "footer mt-3" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row text-muted" }
const _hoisted_4 = {
  key: 0,
  class: "col-auto text-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-auto text-start" }, [
          _createElementVNode("p", { class: "mb-0" }, [
            _createElementVNode("a", {
              class: "text-muted",
              href: "https://adminkit.io/",
              target: "_blank"
            }, [
              _createElementVNode("strong", null, "AdminKit")
            ]),
            _createTextVNode(" © ")
          ])
        ], -1)),
        (_ctx.$user.isUser)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[0] || (_cache[0] = _createTextVNode(" Ваша ссылка для PlaceCard: ")),
              _createElementVNode("strong", null, _toDisplayString(_ctx.$user.placeCardLink), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}