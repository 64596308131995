import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader state-loader"
}
const _hoisted_2 = {
  key: 1,
  class: "alert alert-danger"
}
const _hoisted_3 = {
  key: 2,
  class: "alert alert-danger"
}
const _hoisted_4 = {
  key: 3,
  class: "row"
}
const _hoisted_5 = { class: "col-lg-10 offset-lg-1 py-3 text-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 4,
  class: "row"
}
const _hoisted_8 = { class: "col-lg-10 offset-lg-1 py-3" }
const _hoisted_9 = {
  ref: "video",
  autoplay: "",
  class: "video",
  muted: ""
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12 text-center pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_loader_ui, { "is-show": true })
        ]))
      : _createCommentVNode("", true),
    (_ctx.state === 'denied' && _ctx.cameras.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "alert-message" }, " Доступ к камере закрыт или имеются другие проблемы с камерой ", -1)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.cameras.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "alert-message" }, " Не найдена камера на данном устройстве ", -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.isLoaded && (_ctx.isBlob || _ctx.isFile || _ctx.isString))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              class: "w-100",
              src: _ctx.getUrlResult
            }, null, 8, _hoisted_6)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoaded && _ctx.isNull && _ctx.cameras.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("video", _hoisted_9, null, 512)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_button_ui, {
          class: "btn-outline-primary",
          onClick: _ctx.back
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Назад ")
          ])),
          _: 1
        }, 8, ["onClick"]),
        (_ctx.isNull || _ctx.isString)
          ? (_openBlock(), _createBlock(_component_button_ui, {
              key: 0,
              class: "btn-outline-secondary ms-3 position-relative",
              type: "button",
              onClick: _withModifiers(_ctx.fileClick, ["stop"])
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Прикрепить файл ")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (!_ctx.isNull && (_ctx.isString || _ctx.isFile))
          ? (_openBlock(), _createBlock(_component_button_ui, {
              key: 1,
              class: "btn-outline-secondary ms-3",
              type: "button",
              onClick: _ctx.reset
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Очистить ")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isNull && _ctx.isLoaded && _ctx.cameras.length)
          ? (_openBlock(), _createBlock(_component_button_ui, {
              key: 2,
              class: "btn-primary ms-3",
              type: "button",
              onClick: _ctx.snap
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Сфотографировать ")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (!_ctx.isNull && _ctx.isBlob && _ctx.isLoaded && _ctx.cameras.length)
          ? (_openBlock(), _createBlock(_component_button_ui, {
              key: 3,
              class: "btn-outline-secondary ms-3",
              type: "button",
              onClick: _ctx.reset
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Перефотографировать ")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (!_ctx.isNull)
          ? (_openBlock(), _createBlock(_component_button_ui, {
              key: 4,
              class: "btn-primary ms-3",
              onClick: _ctx.next
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Далее ")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("input", {
      ref: "file",
      class: "upload-files",
      multiple: "",
      type: "file",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args)))
    }, null, 544)
  ]))
}