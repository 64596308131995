import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_area_ui = _resolveComponent("text-area-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-sm",
    title: _ctx.$t('modals.rejectModeration.title')
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.reject && _ctx.reject(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_text_area_ui, {
              modelValue: _ctx.comment,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comment) = $event)),
              placeholder: "Укажите причину отклонения"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_button_ui, {
            "button-class": "btn-danger",
            disabled: !_ctx.comment.length
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Отклонить ")
            ])),
            _: 1
          }, 8, ["disabled"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}