import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12 col-lg-6" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "form-label w-100" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "col-12 col-lg-6" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "form-label w-100" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "col-12 col-lg-6" }
const _hoisted_11 = { class: "mb-3" }
const _hoisted_12 = { class: "form-label w-100" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "col-12 text-center pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    title: _ctx.$t(_ctx.state.title)
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.formHandler && _ctx.formHandler(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        (_ctx.state.step === 5)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "col-12" }, [
                _createElementVNode("div", { class: "alert alert-info" }, [
                  _createElementVNode("div", { class: "alert-message" }, " Загрузите документы в формате JPG, JPEG, PNG или PDF ")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, [
                    _cache[4] || (_cache[4] = _createTextVNode(" Договор найма жилого помещения ")),
                    (_ctx.modal.data.human.fileOne)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: _ctx.API_URL + _ctx.modal.data.human.fileOne,
                          target: "_blank"
                        }, "(Открыть текущий)", 8, _hoisted_5))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("input", {
                    ref: "file1",
                    class: _normalizeClass({ 'is-invalid': _ctx.state.step5.visibleErrors && _ctx.isFile1Error }),
                    type: "file",
                    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectFile($event, 'file1')))
                  }, null, 34),
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "invalid-feedback" }, " Выберите документ ", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, [
                    _cache[6] || (_cache[6] = _createTextVNode(" Документ, удостоверяющий личность ")),
                    (_ctx.modal.data.human.fileTwo)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: _ctx.API_URL + _ctx.modal.data.human.fileTwo,
                          target: "_blank"
                        }, "(Открыть текущий)", 8, _hoisted_9))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("input", {
                    ref: "file2",
                    class: _normalizeClass({ 'is-invalid': _ctx.state.step5.visibleErrors && _ctx.isFile2Error }),
                    type: "file",
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectFile($event, 'file2')))
                  }, null, 34),
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "invalid-feedback" }, " Выберите документ ", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", _hoisted_12, [
                    _cache[8] || (_cache[8] = _createTextVNode(" Документ, подтверждающий обучение в организации образования ")),
                    (_ctx.modal.data.human.fileThree)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: _ctx.API_URL + _ctx.modal.data.human.fileThree,
                          target: "_blank"
                        }, "(Открыть текущий)", 8, _hoisted_13))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("input", {
                    ref: "file3",
                    class: _normalizeClass({ 'is-invalid': _ctx.state.step5.visibleErrors && _ctx.isFile3Error }),
                    type: "file",
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectFile($event, 'file3')))
                  }, null, 34),
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "invalid-feedback" }, " Выберите документ ", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_button_ui, { "button-class": "btn-primary ms-3" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(_ctx.state.btn)), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}