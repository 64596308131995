import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list" }
const _hoisted_2 = { class: "d-flex py-3 item" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.moment(item.date).format('DD MM YYYY HH:mm:ss')), 1),
            _createElementVNode("div", {
              class: "col-auto w-full ms-4",
              innerHTML: item.data
            }, null, 8, _hoisted_4)
          ]))
        }), 256))
      ])
    ]),
    _: 1
  }, 8, ["id", "title"]))
}