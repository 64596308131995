import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popover_ui = _resolveComponent("popover-ui")!

  return (_openBlock(), _createBlock(_component_popover_ui, {
    class: "border-0 m-0",
    content: _ctx.note
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("button", { class: "btn btn-sm py-0 px-1 border-0 note-btn" }, [
        _createElementVNode("div", { class: "note" }, " ? ")
      ], -1)
    ])),
    _: 1
  }, 8, ["content"]))
}