import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar navbar-expand navbar-light navbar-bg" }
const _hoisted_2 = { class: "navbar-collapse collapse" }
const _hoisted_3 = { class: "navbar-nav navbar-align align-items-center" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "nav-icon pe-md-0 dropdown-toggle" }
const _hoisted_7 = { class: "ms-3 fw-bolder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_feather = _resolveComponent("vue-feather")!
  const _component_dropdown_ui = _resolveComponent("dropdown-ui")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", {
      class: "sidebar-toggle cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeSidebar && _ctx.changeSidebar(...args)))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("i", { class: "hamburger align-self-center" }, null, -1)
    ])),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_dropdown_ui, null, {
            button: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", {
                    class: "rounded-circle rounded ava d-flex justify-content-center align-items-center",
                    style: _normalizeStyle('background-color: ' + _ctx.$user.fill)
                  }, [
                    _createVNode(_component_vue_feather, {
                      size: "16",
                      style: _normalizeStyle('color: ' + _ctx.$user.color),
                      type: "user"
                    }, null, 8, ["style"])
                  ], 4)
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$user.firstName), 1)
              ])
            ]),
            list: _withCtx(() => [
              _createElementVNode("div", {
                class: "dropdown-item cursor-pointer",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
              }, " Выход ")
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}