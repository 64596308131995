import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "loader" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = {
  key: 1,
  class: "row"
}
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = {
  key: 2,
  class: "row"
}
const _hoisted_7 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_screen_component = _resolveComponent("screen-component")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    title: _ctx.$t(_ctx.state.title)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        (_ctx.state.step === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-12" }, [
                _createElementVNode("div", { class: "alert alert-info" }, [
                  _createElementVNode("div", { class: "alert-message" }, " Сфотографируйте лицевую сторону удостоверения или прикрепите файл ")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_screen_component, {
                  src: _ctx.state.step2.firstScreen,
                  onOnBack: _ctx.backStep,
                  onOnNext: _ctx.onFirstScreen
                }, null, 8, ["src", "onOnBack", "onOnNext"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.state.step === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-12" }, [
                _createElementVNode("div", { class: "alert alert-info" }, [
                  _createElementVNode("div", { class: "alert-message" }, " Сфотографируйте обратную сторону удостоверения или прикрепите файл ")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_screen_component, {
                  src: _ctx.state.step3.secondScreen,
                  onOnBack: _ctx.backStep,
                  onOnNext: _ctx.onSecondScreen
                }, null, 8, ["src", "onOnBack", "onOnNext"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.state.step === 4)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-12" }, [
                _createElementVNode("div", { class: "alert alert-info" }, [
                  _createElementVNode("div", { class: "alert-message" }, " Сфотографируйте лицевую сторону карточки или прикрепите файл ")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_screen_component, {
                  src: _ctx.state.step4.thirdScreen,
                  onOnBack: _ctx.backStep,
                  onOnNext: _ctx.onThirdScreen
                }, null, 8, ["src", "onOnBack", "onOnNext"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["id", "title"]))
}