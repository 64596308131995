import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-12 mb-2" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "input-group" }
const _hoisted_5 = { class: "col-12 mb-2" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "input-group" }
const _hoisted_8 = {
  key: 0,
  class: "col-12 mb-2"
}
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "input-group" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_input_ui = _resolveComponent("input-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-md",
    title: _ctx.$t('Сброс пароля')
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.requestResetLink && _ctx.requestResetLink(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[7] || (_cache[7] = _createElementVNode("label", null, "Логин", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.login,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.login) = $event)),
                  readonly: ""
                }, null, 8, ["modelValue"]),
                _createElementVNode("button", {
                  class: "btn btn-outline-secondary",
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(_ctx.state.login)))
                }, "Копировать")
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[8] || (_cache[8] = _createElementVNode("label", null, "Телефон", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.phone,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.phone) = $event)),
                  readonly: ""
                }, null, 8, ["modelValue"]),
                _createElementVNode("button", {
                  class: "btn btn-outline-secondary",
                  type: "button",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyToClipboard(_ctx.state.phone)))
                }, "Копировать")
              ])
            ])
          ]),
          (_ctx.state.resetLink)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", null, "Ссылка для сброса пароля", -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_input_ui, {
                      modelValue: _ctx.state.resetLink,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.resetLink) = $event)),
                      readonly: ""
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("button", {
                      class: "btn btn-outline-secondary",
                      type: "button",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.copyToClipboard(_ctx.state.resetLink)))
                    }, "Копировать")
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "col-12 text-center" }, [
            _createElementVNode("p", null, "Ссылка активна в течение 3 дней.")
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_button_ui, {
              "button-class": "btn-primary",
              onClick: _ctx.requestResetLink
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Получить ссылку для сброса пароля')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}