import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 col-lg-6"
}
const _hoisted_3 = { class: "col-12 col-lg-6" }
const _hoisted_4 = { class: "col-12 col-lg-6" }
const _hoisted_5 = { class: "col-12 col-lg-6" }
const _hoisted_6 = { class: "col-12 col-lg-6" }
const _hoisted_7 = { class: "col-12 col-lg-6" }
const _hoisted_8 = { class: "col-12 col-lg-6" }
const _hoisted_9 = {
  key: 1,
  class: "col-12 col-lg-6"
}
const _hoisted_10 = {
  key: 2,
  class: "col-12 col-lg-6"
}
const _hoisted_11 = {
  key: 3,
  class: "col-12 col-lg-6"
}
const _hoisted_12 = { class: "flex items-center" }
const _hoisted_13 = ["disabled", "value", "onBlur", "onFocus"]
const _hoisted_14 = {
  key: 4,
  class: "col-12 col-lg-6"
}
const _hoisted_15 = {
  key: 5,
  class: "col-12 col-lg-6"
}
const _hoisted_16 = {
  key: 6,
  class: "col-12 col-lg-6"
}
const _hoisted_17 = {
  key: 7,
  class: "col-12 col-lg-6"
}
const _hoisted_18 = { class: "flex items-center" }
const _hoisted_19 = ["disabled", "value", "onBlur", "onFocus"]
const _hoisted_20 = { class: "col-12 text-center pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_select_ui = _resolveComponent("select-ui")!
  const _component_form_group_ui = _resolveComponent("form-group-ui")!
  const _component_input_ui = _resolveComponent("input-ui")!
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    title: _ctx.$t(_ctx.state.title)
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.formHandler && _ctx.formHandler(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.entrances.length !== 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Выберите общежитие",
                  label: "Общежитие",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_select_ui, {
                      "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isEntranceIdError,
                      options: _ctx.entrances,
                      placeholder: "Общежитие",
                      searchable: true,
                      values: [ _ctx.state.step1.entranceId ],
                      onOnSelected: _ctx.onSelectedEntrance
                    }, null, 8, ["is-invalid", "options", "values", "onOnSelected"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите фамилию",
              label: "Фамилия",
              required: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.step1.lastName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.step1.lastName) = $event)),
                  "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isLastNameError,
                  placeholder: "Фамилия"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите имя",
              label: "Имя",
              required: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.step1.firstName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.step1.firstName) = $event)),
                  "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isFirstNameError,
                  placeholder: "Имя"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_form_group_ui, { label: "Отчество" }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.step1.middleName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.step1.middleName) = $event)),
                  placeholder: "Отчество"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": _ctx.isHumanExist ? 'Данный ИИН уже есть в системе' : 'Укажите ИИН',
              label: "ИИН",
              required: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.step1.iin,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.step1.iin) = $event)),
                  "is-invalid": _ctx.state.step1.visibleErrors && (_ctx.isIinError || _ctx.isHumanExist),
                  mask: { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}},
                  maxlength: "12",
                  minlength: "12",
                  placeholder: "ИИН"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            }, 8, ["invalid-feedback"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите номер телефона",
              label: "Номер телефона"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.step1.phone,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.step1.phone) = $event)),
                  "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isPhoneError,
                  placeholder: "Номер телефона"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Выберите тип",
              label: "Тип",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_select_ui, {
                  "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isRoleIdError,
                  options: _ctx.humanRoles,
                  placeholder: "Тип",
                  searchable: true,
                  values: [ _ctx.state.step1.roleId ],
                  onOnSelected: _ctx.onSelectedRole
                }, null, 8, ["is-invalid", "options", "values", "onOnSelected"])
              ]),
              _: 1
            })
          ]),
          (_ctx.state.step1.roleId === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Выберите учебное заведение",
                  label: "Учебное заведение",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_select_ui, {
                      disabled: _ctx.state.step1.roleId !== 1,
                      "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isInstitutionIdError,
                      options: _ctx.institutions,
                      placeholder: "Учебное заведение",
                      searchable: true,
                      values: [ _ctx.state.step1.prop1 ],
                      onOnSelected: _ctx.onSelectedInstitution
                    }, {
                      option: _withCtx((props) => [
                        _createTextVNode(_toDisplayString(props.option.type === 'college' ? 'Колледж -' : 'Университет -') + " " + _toDisplayString(props.option.name), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "is-invalid", "options", "values", "onOnSelected"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.state.step1.roleId === 1 && typeof _ctx.getInstitution !== 'undefined' && _ctx.getInstitution?.type !== 'college')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Выберите форму обучения",
                  label: "Форма обучения",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_select_ui, {
                      disabled: _ctx.state.step1.roleId !== 1,
                      "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isEducationIdError,
                      options: _ctx.educations,
                      placeholder: "Форма обучения",
                      searchable: true,
                      values: [ _ctx.state.step1.prop2 ],
                      onOnSelected: _ctx.onSelectedEducation
                    }, null, 8, ["disabled", "is-invalid", "options", "values", "onOnSelected"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.state.step1.roleId === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Выберите дату поступления",
                  label: "Дата поступления",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_date_picker, {
                      timezone: "UTC",
                      modelValue: _ctx.state.step1.enteredAt,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.step1.enteredAt) = $event)),
                      class: "inline-block h-full",
                      is24hr: true,
                      mode: "date"
                    }, {
                      default: _withCtx(({ inputValue, togglePopover }) => [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("input", {
                            class: _normalizeClass(["form-control", {'is-invalid': _ctx.isEnteredAtError && _ctx.state.step1.visibleErrors, 'bg-white': _ctx.state.step1.roleId === 1 }]),
                            disabled: _ctx.state.step1.roleId !== 1,
                            placeholder: "Дата поступления",
                            readonly: "",
                            timezone: 'Asia/Qyzylorda',
                            value: inputValue,
                            onBlur: ($event: any) => (togglePopover()),
                            onFocus: ($event: any) => (togglePopover())
                          }, null, 42, _hoisted_13),
                          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "invalid-feedback" }, " Укажите дату ", -1))
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.state.step1.roleId === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Укажите номер комнаты",
                  label: "Номер комнаты"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_input_ui, {
                      modelValue: _ctx.state.step1.roomNumber,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.step1.roomNumber) = $event)),
                      disabled: _ctx.state.step1.roleId !== 1,
                      "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isRoomNumberError,
                      placeholder: "Номер комнаты"
                    }, null, 8, ["modelValue", "disabled", "is-invalid"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.state.step1.roleId === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Укажите курс обучения",
                  label: "Курс обучения",
                  required: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_input_ui, {
                      modelValue: _ctx.state.step1.courseNumber,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.step1.courseNumber) = $event)),
                      disabled: _ctx.state.step1.roleId !== 1,
                      "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isCourseNumberError,
                      mask: { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}},
                      maxlength: "1",
                      placeholder: "Курс обучения"
                    }, null, 8, ["modelValue", "disabled", "is-invalid"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.state.step1.roleId === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Укажите номер договора",
                  label: "Номер договора"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_input_ui, {
                      modelValue: _ctx.state.step1.contractNumber,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.step1.contractNumber) = $event)),
                      disabled: _ctx.state.step1.roleId !== 1,
                      "is-invalid": _ctx.state.step1.visibleErrors && _ctx.isContractNumberError,
                      placeholder: "Номер договора"
                    }, null, 8, ["modelValue", "disabled", "is-invalid"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.state.step1.roleId === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Выберите дату заключения договора",
                  label: "Дата заключения договора",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_date_picker, {
                      timezone: "UTC",
                      modelValue: _ctx.state.step1.contractDate,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.step1.contractDate) = $event)),
                      class: "inline-block h-full",
                      is24hr: true,
                      mode: "date"
                    }, {
                      default: _withCtx(({ inputValue, togglePopover }) => [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("input", {
                            class: _normalizeClass(["form-control", {'is-invalid': _ctx.isContractDateError && _ctx.state.step1.visibleErrors, 'bg-white': _ctx.state.step1.roleId === 1 }]),
                            disabled: _ctx.state.step1.roleId !== 1,
                            placeholder: "Дата заключения договора",
                            readonly: "",
                            timezone: 'Asia/Qyzylorda',
                            value: inputValue,
                            onBlur: ($event: any) => (togglePopover()),
                            onFocus: ($event: any) => (togglePopover())
                          }, null, 42, _hoisted_19),
                          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "invalid-feedback" }, " Укажите дату ", -1))
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_button_ui, { "button-class": "btn-primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.state.btn)), 1)
              ]),
              _: 1
            })
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}