import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-fixed top-0 bottom-0 w-100 loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.modal.loader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_loader_ui, { "is-show": true })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.modal.isShow)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.initialComponent), {
              key: 0,
              id: _ctx.modalName,
              ref: "handle"
            }, null, 8, ["id"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}