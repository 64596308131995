import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "select-dormitory",
  ref: "dropdown"
}
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "list-group position-absolute w-100 z-index-100"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "list-group-item"
}
const _hoisted_7 = {
  key: 1,
  class: "invalid-feedback d-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: _normalizeClass(["form-control", { 'is-invalid': _ctx.isInvalid }]),
        placeholder: _ctx.placeholder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = true)),
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)))
      }, null, 42, _hoisted_3), [
        [_vModelText, _ctx.searchQuery]
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-outline-secondary",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          width: "16",
          height: "16",
          fill: "currentColor",
          class: "bi bi-chevron-down",
          viewBox: "0 0 16 16"
        }, [
          _createElementVNode("path", {
            "fill-rule": "evenodd",
            d: "M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
          })
        ], -1)
      ]))
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option) => {
            return (_openBlock(), _createElementBlock("li", {
              key: option.id,
              class: "list-group-item list-group-item-action",
              onClick: ($event: any) => (_ctx.selectOption(option))
            }, _toDisplayString(option.name) + " (" + _toDisplayString(option.bin) + ") ", 9, _hoisted_5))
          }), 128)),
          (_ctx.filteredOptions.length === 0)
            ? (_openBlock(), _createElementBlock("li", _hoisted_6, " Нет результатов "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isInvalid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.invalidFeedback), 1))
      : _createCommentVNode("", true)
  ], 512))
}