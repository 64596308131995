<!-- SelectDormitory.vue -->
<template>
    <div class="select-dormitory" ref="dropdown">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          :placeholder="placeholder"
          v-model="searchQuery"
          @focus="isOpen = true"
          @input="onSearch"
          :class="{ 'is-invalid': isInvalid }"
        />
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="toggleDropdown"
        >
        <svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  fill="currentColor"
  class="bi bi-chevron-down"
  viewBox="0 0 16 16"
>
  <path
    fill-rule="evenodd"
    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
  />
</svg>

        </button>
      </div>
      <ul v-if="isOpen" class="list-group position-absolute w-100 z-index-100">
        <li
          v-for="option in filteredOptions"
          :key="option.id"
          class="list-group-item list-group-item-action"
          @click="selectOption(option)"
        >
          {{ option.name }} ({{ option.bin }})
        </li>
        <li v-if="filteredOptions.length === 0" class="list-group-item">
          Нет результатов
        </li>
      </ul>
      <div class="invalid-feedback d-block" v-if="isInvalid">
        {{ invalidFeedback }}
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, watch, onMounted, onUnmounted,PropType } from 'vue';
  
  export interface IOption {
    id: string | number;
    name: string;
    bin?: string;
  }
  
  export default defineComponent({
    name: 'SelectDormitory',
    props: {
      modelValue: {
        type: [String, Number, null] as PropType<string | number | null>,
        default: null,
      },
      options: {
        type: Array as () => IOption[],
        default: () => [],
      },
      placeholder: {
        type: String,
        default: 'Введите название или БИН',
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
      invalidFeedback: {
        type: String,
        default: 'Выберите общежитие',
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const searchQuery = ref('');
      const isOpen = ref(false);
      const dropdown = ref<HTMLElement | null>(null);
  
      const filteredOptions = computed(() => {
        if (!searchQuery.value) return props.options ?? []; 
        const query = searchQuery.value.toLowerCase();
        return props.options.filter(
          (option) =>
            option.name.toLowerCase().includes(query) ||
            option.bin?.toLowerCase().includes(query)
        );
      });
  
      const selectOption = (option: IOption) => {
        emit('update:modelValue', option.id);
        searchQuery.value = `${option.name} (${option.bin})`;
        isOpen.value = false;
      };

    //   watch(
    //   () => props.modelValue,
    //   (newVal) => {
    //     const selected = props.options.find((option) => option.id === newVal);
    //     if (selected) {
    //       searchQuery.value = selected.name; // Убираем BIN
    //     } else {
    //       searchQuery.value = '';
    //     }
    //   },
    //   { immediate: true }
    // );

    watch(
      () => props.options,
      (newOptions) => {
        if (newOptions.length > 0 && props.modelValue) {
          const selected = newOptions.find((option) => option.id === props.modelValue);
          if (selected) {
            searchQuery.value = selected.name; // Убираем BIN
          } else {
            searchQuery.value = '';
          }
        }
      },
      { immediate: true } // Запускаем сразу
    );

  
      const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
      };
  
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdown.value && !dropdown.value.contains(event.target as Node)) {
          isOpen.value = false;
        }
      };
  
      onMounted(() => {
        window.addEventListener('click', handleClickOutside);
      });
  
      onUnmounted(() => {
        window.removeEventListener('click', handleClickOutside);
      });
  
      watch(
        () => props.modelValue,
        (newVal) => {
          const selected = props.options.find((option) => option.id === newVal);
          if (selected) {
            searchQuery.value = `${selected.name} (${selected.bin})`;
          } else {
            searchQuery.value = '';
          }
        },
        { immediate: true }
      );
  
      const onSearch = () => {
        isOpen.value = true;
      };
  
      return {
        searchQuery,
        isOpen,
        filteredOptions,
        selectOption,
        toggleDropdown,
        dropdown,
        onSearch,
      };
    },
  });
  </script>
  
  <style scoped>
  .select-dormitory {
    position: relative;
  }
  
  .select-dormitory ul {
    max-height: 200px;
    overflow-y: auto;
  }
  
  .list-group-item {
    cursor: pointer;
  }
  </style>
  