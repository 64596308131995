import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "table table-bordered" }
const _hoisted_4 = { class: "w-50 py-2" }
const _hoisted_5 = { class: "p-0" }
const _hoisted_6 = { class: "w-50 py-2" }
const _hoisted_7 = { class: "p-0" }
const _hoisted_8 = { class: "py-2" }
const _hoisted_9 = { class: "p-0" }
const _hoisted_10 = { class: "py-2" }
const _hoisted_11 = { class: "p-0" }
const _hoisted_12 = { class: "py-2" }
const _hoisted_13 = { class: "p-0" }
const _hoisted_14 = { class: "py-2" }
const _hoisted_15 = { class: "p-0" }
const _hoisted_16 = { class: "py-2" }
const _hoisted_17 = { class: "p-0" }
const _hoisted_18 = { class: "py-2" }
const _hoisted_19 = { class: "p-0" }
const _hoisted_20 = { class: "py-2" }
const _hoisted_21 = { class: "p-0" }
const _hoisted_22 = { class: "py-2" }
const _hoisted_23 = { class: "p-0" }
const _hoisted_24 = { class: "py-2" }
const _hoisted_25 = { class: "p-0" }
const _hoisted_26 = { class: "py-2" }
const _hoisted_27 = { class: "p-0" }
const _hoisted_28 = { class: "col-12" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-12 col-lg-4" }
const _hoisted_31 = ["href"]
const _hoisted_32 = ["src"]
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "col-12 col-lg-4" }
const _hoisted_35 = ["href"]
const _hoisted_36 = ["src"]
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { class: "col-12 col-lg-4" }
const _hoisted_39 = ["href"]
const _hoisted_40 = ["src"]
const _hoisted_41 = { key: 1 }
const _hoisted_42 = { class: "col-12" }
const _hoisted_43 = { class: "table table-bordered" }
const _hoisted_44 = { class: "py-2" }
const _hoisted_45 = { class: "p-0" }
const _hoisted_46 = ["href"]
const _hoisted_47 = { key: 1 }
const _hoisted_48 = { class: "py-2" }
const _hoisted_49 = { class: "p-0" }
const _hoisted_50 = ["href"]
const _hoisted_51 = { key: 1 }
const _hoisted_52 = { class: "py-2" }
const _hoisted_53 = { class: "p-0" }
const _hoisted_54 = ["href"]
const _hoisted_55 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-lg",
    title: "Информация о студенте"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "col-12" }, [
          _createElementVNode("h4", null, "Общие данные")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("table", _hoisted_3, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, [
                  _cache[0] || (_cache[0] = _createElementVNode("b", null, "ФИО:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.getFullName()), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, [
                  _cache[1] || (_cache[1] = _createElementVNode("b", null, "ИИН:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.iin), 1)
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, [
                  _cache[2] || (_cache[2] = _createElementVNode("b", null, "Тип:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.roleId === 1 ? 'Студент' : 'Сотрудник') + " - " + _toDisplayString(_ctx.human.entranceName), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, [
                  _cache[3] || (_cache[3] = _createElementVNode("b", null, "Номер телефона:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.phone ? _ctx.human.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5') : '-'), 1)
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, [
                  _cache[4] || (_cache[4] = _createElementVNode("b", null, "Код карты:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.rfid), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, [
                  _cache[5] || (_cache[5] = _createElementVNode("b", null, "Учебное заведение:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.prop1 ? _ctx.institutions.find((i) => i.id === _ctx.human.prop1).name : '-'), 1)
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_16, [
                _createElementVNode("span", _hoisted_17, [
                  _cache[6] || (_cache[6] = _createElementVNode("b", null, "Форма обучение:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.educations.find((i) => i.id === _ctx.human.prop1)?.name), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_18, [
                _createElementVNode("span", _hoisted_19, [
                  _cache[7] || (_cache[7] = _createElementVNode("b", null, "Дата поступления:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.enteredAt ? _ctx.moment(_ctx.human.enteredAt).locale('ru').format('DD MMMM YYYY') : '-'), 1)
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_20, [
                _createElementVNode("span", _hoisted_21, [
                  _cache[8] || (_cache[8] = _createElementVNode("b", null, "Номер комнаты:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.roomNumber ? _ctx.human.roomNumber : '-'), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_22, [
                _createElementVNode("span", _hoisted_23, [
                  _cache[9] || (_cache[9] = _createElementVNode("b", null, "Курс обучения:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.courseNumber ? _ctx.human.courseNumber : '-'), 1)
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_24, [
                _createElementVNode("span", _hoisted_25, [
                  _cache[10] || (_cache[10] = _createElementVNode("b", null, "Номер договора:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.contractNumber ? _ctx.human.contractNumber : '-'), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_26, [
                _createElementVNode("span", _hoisted_27, [
                  _cache[11] || (_cache[11] = _createElementVNode("b", null, "Дата заключения договора:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.human.contractDate ? _ctx.moment(_ctx.human.contractDate).locale('ru').format('DD MMMM YYYY') : '-'), 1)
                ])
              ])
            ])
          ])
        ]),
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "col-12 mt-3" }, [
          _createElementVNode("h4", null, "Удостоверение и карточка")
        ], -1)),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              (_ctx.human.firstScreen)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.API_URL + _ctx.human.firstScreen,
                    target: "_blank"
                  }, [
                    _cache[12] || (_cache[12] = _createTextVNode(" Лицевая сторона удостоверения ")),
                    _createElementVNode("img", {
                      class: "w-100",
                      src: _ctx.API_URL + _ctx.human.firstScreen
                    }, null, 8, _hoisted_32)
                  ], 8, _hoisted_31))
                : (_openBlock(), _createElementBlock("div", _hoisted_33, " Лицевая сторона удостоверения "))
            ]),
            _createElementVNode("div", _hoisted_34, [
              (_ctx.human.secondScreen)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.API_URL + _ctx.human.secondScreen,
                    target: "_blank"
                  }, [
                    _cache[13] || (_cache[13] = _createTextVNode(" Обратная сторона удостоверения ")),
                    _createElementVNode("img", {
                      class: "w-100",
                      src: _ctx.API_URL + _ctx.human.secondScreen
                    }, null, 8, _hoisted_36)
                  ], 8, _hoisted_35))
                : (_openBlock(), _createElementBlock("div", _hoisted_37, " Обратная сторона удостоверения "))
            ]),
            _createElementVNode("div", _hoisted_38, [
              (_ctx.human.thirdScreen)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.API_URL + _ctx.human.thirdScreen,
                    target: "_blank"
                  }, [
                    _cache[14] || (_cache[14] = _createTextVNode(" Лицевая сторона карточки ")),
                    _createElementVNode("img", {
                      class: "w-100",
                      src: _ctx.API_URL + _ctx.human.thirdScreen
                    }, null, 8, _hoisted_40)
                  ], 8, _hoisted_39))
                : (_openBlock(), _createElementBlock("div", _hoisted_41, " Лицевая сторона карточки "))
            ])
          ])
        ]),
        _cache[17] || (_cache[17] = _createElementVNode("div", { class: "col-12 mt-3" }, [
          _createElementVNode("h4", null, "Документы")
        ], -1)),
        _createElementVNode("div", _hoisted_42, [
          _createElementVNode("table", _hoisted_43, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_44, [
                _createElementVNode("span", _hoisted_45, [
                  (_ctx.human.fileOne)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: _ctx.API_URL + _ctx.human.fileOne,
                        target: "_blank"
                      }, " Договор найма жилого помещения ", 8, _hoisted_46))
                    : (_openBlock(), _createElementBlock("div", _hoisted_47, "Договор найма жилого помещения"))
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_48, [
                _createElementVNode("span", _hoisted_49, [
                  (_ctx.human.fileTwo)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: _ctx.API_URL + _ctx.human.fileTwo,
                        target: "_blank"
                      }, " Документ, удостоверяющий личность ", 8, _hoisted_50))
                    : (_openBlock(), _createElementBlock("div", _hoisted_51, "Документ, удостоверяющий личность"))
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_52, [
                _createElementVNode("span", _hoisted_53, [
                  (_ctx.human.fileThree)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: _ctx.API_URL + _ctx.human.fileThree,
                        target: "_blank"
                      }, " Документ, подтверждающий обучение в организации образования ", 8, _hoisted_54))
                    : (_openBlock(), _createElementBlock("div", _hoisted_55, "Документ, подтверждающий обучение в организации образования"))
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}