<template>
    <div class="select-month" ref="dropdown">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          :placeholder="placeholder"
          v-model="searchQuery"
          @focus="isOpen = true"
          :class="{ 'is-invalid': isInvalid }"
          readonly
        />
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="toggleDropdown"
        >
        <svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  fill="currentColor"
  class="bi bi-chevron-down"
  viewBox="0 0 16 16"
>
  <path
    fill-rule="evenodd"
    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
  />
</svg>

        </button>
      </div>
      <ul v-if="isOpen" class="list-group position-absolute w-100 z-index-100">
        <li
          v-for="option in options"
          :key="option.id"
          class="list-group-item list-group-item-action"
          @click="selectOption(option)"
        >
          {{ option.name }}
        </li>
        <li v-if="options.length === 0" class="list-group-item">
          Нет данных
        </li>
      </ul>
      <div class="invalid-feedback d-block" v-if="isInvalid">
        {{ invalidFeedback }}
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, watch, PropType, onMounted, onUnmounted } from "vue";
  
  export interface IOption {
    id: string | number;
    name: string;
  }
  
  export default defineComponent({
    name: "SelectMonth",
    props: {
      modelValue: {
        type: [String, Number, null] as PropType<string | number | null>,
        default: null,
      },
      options: {
        type: Array as PropType<IOption[]>,
        default: () => [],
      },
      placeholder: {
        type: String,
        default: "Выберите месяц",
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
      invalidFeedback: {
        type: String,
        default: "Выберите месяц",
      },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
      const searchQuery = ref<string>("");
      const isOpen = ref<boolean>(false);
      const dropdown = ref<HTMLElement | null>(null);
  
      // Обновление отображаемого текста при изменении modelValue
      watch(
        () => props.modelValue,
        (newVal) => {
          const selected = props.options.find((option) => option.id.toString() === newVal?.toString());
          searchQuery.value = selected ? selected.name : "";
        },
        { immediate: true }
      );
  
      // Выбор месяца
      const selectOption = (option: IOption) => {
        emit("update:modelValue", option.id.toString());
        searchQuery.value = option.name;
        isOpen.value = false;
      };
  
      // Открытие/закрытие выпадающего списка
      const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
      };
  
      // Закрытие при клике вне компонента
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdown.value && !dropdown.value.contains(event.target as Node)) {
          isOpen.value = false;
        }
      };
  
      onMounted(() => {
        window.addEventListener("click", handleClickOutside);
      });
  
      onUnmounted(() => {
        window.removeEventListener("click", handleClickOutside);
      });
  
      return {
        searchQuery,
        isOpen,
        selectOption,
        toggleDropdown,
        dropdown,
      };
    },
  });
  </script>
  
  <style scoped>
  .select-month {
    position: relative;
  }
  
  .select-month ul {
    max-height: 200px;
    overflow-y: auto;
  }
  
  .list-group-item {
    cursor: pointer;
  }
  </style>
  